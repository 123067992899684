import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonApp, IonRouterOutlet, actionSheetController } from "@ionic/vue";
import { URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import router from "@/router";
import { computed, onMounted, watch } from "vue";
import { App } from "@capacitor/app";
import { ActionPerformed, PushNotifications } from "@capacitor/push-notifications";
import { store } from "@/store";
import StorageService from "./services/storage.service";
import moment from "moment";
import i18n from "@/i18n";
import { LiveUpdate } from "@capawesome/capacitor-live-update";
import Toast from "./componentables/toast";
import { SplashScreen } from "@capacitor/splash-screen";
import { useRoute } from "vue-router";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import ApiService from "@/services/api.service";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

moment.locale(i18n.global.locale.value);
const user = computed(() => store.getters["profile/user"]);
const toast = new Toast();
const route = useRoute();

App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  const slug = getSlug(event.url);

  if (slug) {
    router.push("/" + getSlug(slug));
  }
});

App.addListener("resume", async function () {
  await updateApp();
});

const updateApp = async () => {
  if (
    process.env.VUE_APP_LIVE_UPDATES_ENABLED == false ||
    !Capacitor.isNativePlatform()
  ) {
    if (Capacitor.isNativePlatform()) {
      await LiveUpdate.reset();
      await updateDeviceId();
    }
    return;
  }

  const versions = await (await fetch(`https://app.sportspoule.com/versions.json?t=${Date.now()}`)).json();
  const currentBundleId = (await LiveUpdate.getCurrentBundle()).bundleId;
  const channel = await StorageService.get("channel") ?? 'stable';
  const channelData = versions.channels[channel]
  const latestBundleId = channelData.bundleId

  if (
    currentBundleId !== latestBundleId &&
    compareVersions(channelData.compatibleVersion, (await App.getInfo()).version) < 1
  ) {
    SplashScreen.show({
      autoHide: false,
    });
    if (
      !(await LiveUpdate.getBundles()).bundleIds.find(
        (bundleId) => bundleId === latestBundleId
      )
    ) {
      await LiveUpdate.downloadBundle({
        bundleId: latestBundleId,
        url: "https://app.sportspoule.com/bundles/" + latestBundleId + ".zip",
      });
    }
    await LiveUpdate.setNextBundle({
      bundleId: latestBundleId,
    });
    await LiveUpdate.reload();
  }
};

const compareVersions = (a: string, b: string) => {
  const pa = a.split(".");
  const pb = b.split(".");
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) return 1;
    if (nb > na) return -1;
    if (!isNaN(na) && isNaN(nb)) return 1;
    if (isNaN(na) && !isNaN(nb)) return -1;
  }
  return 0;
};

function getSlug(url: string) {
  [
    process.env.VUE_APP_BASE_URL + "/",
    process.env.VUE_APP_BASE_URL,
    "app.sportspoule.com/",
    "app.sportspoule.com",
    "http://",
    "https://",
    "sportspoule:///",
    "sportspoule://",
  ].forEach((item) => {
    url = url.replace(item, "");
  });

  return url;
}

const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");

watch(
  user,
  async (newUser) => {
    if (Object.keys(newUser).length > 0) {
      if (isPushNotificationsAvailable) {
        await checkPermissions();
      }
      await updateDeviceId();
    }
  },
  {
    immediate: true,
  }
);

async function updateDeviceId() {
  const id = (await Device.getId()).identifier;
  return ApiService.post(`/profile/device-id`, {
    device_id: id,
  });
}

watch(
  () => route.path,
  (path) => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    if (path.startsWith('/profile')) {
      StatusBar.setStyle({
        style: Style.Light,
      });
    } else {
      StatusBar.setStyle({
        style: Style.Dark,
      });
    }
  }
);

async function checkPermissions() {
  if ((await StorageService.get("pushNotifications")) != "false") {
    if ((await PushNotifications.checkPermissions()).receive == "granted") {
      initPushNotifications();
    } else {
      (
        await actionSheetController.create({
          header: "Do you want to enable push notifications?",
          buttons: [
            {
              text: "Yes",
              handler: () => {
                initPushNotifications();
              },
            },
            {
              text: "No",
              role: "cancel",
              handler: () => {
                StorageService.set("pushNotifications", "false");
              },
            },
          ],
        })
      ).present();
    }
  }
}

function initPushNotifications() {
  PushNotifications.requestPermissions().then(({ receive }) => {
    if (receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
      PushNotifications.addListener("registration", (token: any) => {
        // send token to server
        if (!("fcmTokens" in user.value)) return;
        const tokens = (user.value.fcmTokens || "").split(",").filter((t: string) => t);
        if (!tokens.includes(token.value)) {
          tokens.push(token.value);
          user.value.fcmTokens = tokens.join(",");
          store.dispatch("profile/update", user.value);
        }
      });

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (action: ActionPerformed) => {
          if (action.notification.data.url) {
            const url = action.notification.data.url;
            const slug = getSlug(url);
            if (slug) {
              router.push("/" + getSlug(slug));
            }
          }
        }
      );
    } else {
      // Show some error
      alert("error");
    }
  });
}

onMounted(async () => {
  if (Capacitor.isNativePlatform()) {
    SplashScreen.hide();
    await LiveUpdate.ready();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonRouterOutlet))
    ]),
    _: 1
  }))
}
}

})